import projects_webdev from '../assets/icons/projects_webdev.svg';

export const Data = [
  {
    id: 1,
    title: 'Pinned',
    authors: 'Sydnee Haley',
    date: '2022',
    type: 'Web App',
    languages: [{ label: 'React' }, { label: 'Google Firebase' }, { label: 'Tailwind CSS' }],
    progress: '80%',
    progressInt: 80,
    progressColor: '#10EA7D',
    headline: 'A Pinterest inspired web app for pinned images',
    icon: projects_webdev,
    demoUrl: `https://pinned-images-gallery.netlify.app/`,
    repoUrl: `https://github.com/sydneehaley/pinned`,
    img: [{ id: 1, alt: 'Pinned Preview', link: null }],
  },
  {
    id: 2,
    title: 'KetoCookBook',
    authors: 'Sydnee Haley',
    date: '2021',
    type: 'Web App',
    languages: [{ label: 'React' }, { label: 'Node.js' }, { label: 'Express.js' }, { label: 'Mongo NeDB' }],
    progress: '50%',
    progressInt: 50,
    progressColor: '#FAC943',
    headline: 'Search, share, and store recipes for your ketogenic diet',
    icon: projects_webdev,
    demoUrl: `https://ketocookbook-app.herokuapp.com/`,
    repoUrl: `https://github.com/sydneehaley/KetoCookBook`,
    img: [{ id: 1, alt: 'KetoCookBook Preview', link: null }],
  },
  {
    id: 3,
    title: 'DevFinder',
    authors: 'Sydnee Haley',
    date: '2022',
    type: 'Web App',
    languages: [{ label: 'Javascript' }, { label: 'Node.js' }, { label: 'Express.js' }, { label: 'Cheerio' }],
    progress: '100%',
    progressInt: 100,
    progressColor: '#10EA7D',
    headline: 'A job board for web and software developer job seekers.',
    icon: projects_webdev,
    demoUrl: `https://github.com/sydneehaley/devFinder`,
    repoUrl: `https://github.com/sydneehaley/devFinder`,
    img: [{ id: 1, alt: 'DevFinder Preview', link: null }],
  },
  {
    id: 4,
    title: 'Ornamints',
    authors: 'Sydnee Haley',
    date: '2021',
    type: 'Landing Page',
    languages: [{ label: 'Next.js' }, { label: 'SCSS' }],
    progress: '100%',
    progressInt: 25,
    progressColor: '#E3694C',
    headline: 'A landing page for an NFT marketplace',
    description: `A landing page for an NFT marketplace`,
    icon: projects_webdev,
    demoUrl: `https://github.com/sydneehaley/ornamints`,
    repoUrl: `https://github.com/sydneehaley/ornamints`,
    img: [{ id: 1, alt: 'Ornamints Preview', link: null }],
  },
];

export default Data;
