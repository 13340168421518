export const Data = [
  {
    title: 'Landing Pages',
    number: 1,
    content: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
      nonumy eirmod tempor invidunt ut labore et dolore.`,
  },
  {
    title: 'Themes',
    number: 2,
    content: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
      nonumy eirmod tempor invidunt ut labore et dolore.`,
  },
  {
    title: 'Single Page',
    number: 3,
    content: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
      nonumy eirmod tempor invidunt ut labore et dolore.`,
  },
  {
    title: 'Multi Page',
    number: 4,
    content: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
        nonumy eirmod tempor invidunt ut labore et dolore.`,
  },
];
